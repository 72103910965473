<div class="my-5">
  <footer class="text-center text-lg-start">
    <!-- <div class="d-flex justify-content-center py-5">
    </div> -->
    <!-- Copyright -->
    <div class="text-center text-white p-1">
      &copy; {{ currentDate | date : "yyyy" }} Copyright:
      <a target="_blank" class="text-white" href="https://labworkz.com/">
        LabWorkz LLC
      </a>
    </div>
    <div class="version text-center text-white p-1">
      <p>Version: <span *ngIf="version" [innerText]="version"></span></p>
    </div>
    <!-- Copyright -->
  </footer>
</div>
