import { Component, OnInit } from '@angular/core';
import { User } from '@core/models/user';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-get-started-wizard',
  templateUrl: './get-started-wizard.component.html',
  styleUrl: './get-started-wizard.component.scss'
})
export class GetStartedWizardComponent implements OnInit {
  progress = 0;
  progressStep = 0;
  private user!: User;
  onboardingDismissed = false;

  steps = [
    {
      id: 1,
      title: 'Create Scanner',
      description: 'Set up your first scanner.',
      completed: false
    },
    {
      id: 2,
      title: 'Create Scan',
      description: 'Initiate your first scan.',
      completed: false
    },
    {
      id: 3,
      title: 'Review Scan Results',
      description: 'Analyze your scan results.',
      completed: false
    },
    {
      id: 4,
      title: 'Review Scan Remediation results',
      description: 'Check remediation progress.',
      completed: false
    },
    {
      id: 5,
      title: 'Review Assets',
      description: 'Review your assets and findings.',
      completed: false
    },
    {
      id: 6,
      title: 'Invite Team Members',
      description: 'Invite Team Members to help you.',
      completed: false
    }
  ];

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.getUserModel().subscribe(user => {
      if (user) {
        this.user = new User(user);
        this.progressStep = this.user.onboardingStep || 0;
        this.onboardingDismissed = this.user.onboardingDismissed || false;
        this.updateSteps();
        this.updateProgress();
      }
    });

  }


  updateSteps() {
    this.steps.forEach((step, index) => {
      step.completed = index < this.progressStep;
    });
  }

  updateProgress() {
    const completedSteps = this.steps.filter(step => step.completed).length;
    // round to the nearest whole number
    this.progress = Math.round((completedSteps / this.steps.length) * 100);
  }


  completeStep(index: number) {
    this.steps[index].completed = true;
    this.progressStep++;
    this.user.onboardingStep = this.progressStep;
    this.userService.update(this.user, this.user.uid).then(() => {
    });
    this.updateProgress();
  }

  // Check User Profile to see if onboarding is done or dismissed

  isOnboardingDone(): boolean {
    return this.userService.isOnboardingDone();
  }

  dismissOnboarding() {
    this.user.onboardingDismissed = true;
    this.onboardingDismissed = true;
    this.userService.dismissOnboarding();
  }





}
