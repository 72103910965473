import { Injectable } from '@angular/core';
import { FirestoreService } from '../../services/firestore.abstract.service';
import { DocumentData, Firestore, limit, orderBy, where, query, collection, getDocs } from '@angular/fire/firestore';
import { TenableScan } from '../models/tenable-scan';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';


@Injectable({
  providedIn: 'root'
})
export class TenableScansFireStoreService extends FirestoreService<TenableScan> {

  protected override basePath: string = "tenable_scan";
  private company?: Company;


  constructor(fireStore: Firestore, private companySevice: CompanyService
  ) {
    super(fireStore);
  }

  setUpCompanyUsers(): void {
    if (this.companySevice.company) {
      this.company = this.companySevice.company;
    }

    this.companySevice.getCurrentUserCompany().subscribe((company: Company) => {
      this.company = company;
    });
  }

  // TODO Update this to do pagination.
  async getScans(): Promise<any[]> {
    if (!this.company) {
      this.setUpCompanyUsers();
    }
    if (this.company) {
      const companyId = this.company.uid;
      const customQuery = query(
        collection(this.fireStore, `${this.basePath}`),
        where("companyUid", "==", companyId),
        orderBy("creation_date", "desc"),
        limit(100)
      );

      const queryData = await getDocs(customQuery);

      if (queryData.empty) {
        return [];
      } else {
        const dataObjects: DocumentData[] = [];
        queryData.forEach((doc: any) => {
          const obj = new TenableScan(doc.data());
          dataObjects.push(obj);
        });
        return dataObjects;
      }
    } else {
      return [];
    }
  }

  async updateScans(scans: TenableScan[]): Promise<void> {
    scans.forEach((scan) => {
      if (this.company) {
        scan.companyUid = this.company.uid;
        this.update(scan, scan.schedule_uuid).then(() => { });
      }
    });
  }

  getScan(uid: string) {
    if (!this.company) {
      this.setUpCompanyUsers();
    }
    return this.getDocument(uid);
  }

  updateScan(scan: any) {
    if (!this.company) {
      this.setUpCompanyUsers();
    }
    return this.update(scan, scan.schedule_uuid);
  }
}
