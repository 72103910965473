import { Injectable } from '@angular/core';
import { TenableScanDetails } from '../models/tenable-scan-details';
import { FirestoreService } from '../../services/firestore.abstract.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TenableScanFireStoreDetailsService extends FirestoreService<TenableScanDetails> {

  protected override basePath: string = "tenable_scan_details";
  constructor(fireStore: Firestore,
  ) {
    super(fireStore);
  }

  getScan(uid: string) {
    return this.getDocument(uid);
  }

  updateScan(scan: any) {
    return this.update(scan, scan.info.schedule_uuid);
  }
}
