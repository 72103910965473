<div class="card p-3 shadow-lg" id="get-start-widget" *ngIf="!onboardingDismissed">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h5 class="card-title m-0">Get started with Scan Ninja 🚀</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissOnboarding()" (keypress)="dismissOnboarding()"></button>
  </div>

  <!-- Progress Bar -->
  <div class="progress mb-3">
    <div class="progress-bar bg-success" role="progressbar" [style.width.%]="progress" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
      <span class="fw-bold">
        {{ progress }}%
      </span>
    </div>
  </div>

  <!-- Steps List -->
  <ul class="list-group list-group-flush">
    <li *ngFor="let step of steps; let i = index" class="list-group-item d-flex align-items-center" [ngClass]="{'completed': step.completed}" (keypress)="completeStep(i)"
      (click)="completeStep(i)" [tabindex]="i">

      <i class="bi" [ngClass]="step.completed ? 'bi-check-circle-fill text-success' : 'bi-circle text-muted'">
      </i>
      <div class="ms-2">
        <strong [class.text-muted]="step.completed">{{ step.title }}</strong>
        <div *ngIf="!step.completed" class="text-muted text-sm">{{ step.description }}</div>
      </div>
    </li>
  </ul>

  <!-- Dismiss Button -->
  <div class="text-center mt-3">
    <button class="btn btn-link text-muted" (click)="dismissOnboarding()">Dismiss checklist</button>
  </div>
</div>
