<div class="loader-container">
  <div class="mesh-loader">
    <div class="d-flex justify-content-center message-container">
      <div class="loading-message">
        <p [ngClass]="messageClass" [innerText]="randomMessage"></p>
      </div>
    </div>

    <div class="set-one">
      <div class="circle" [ngClass]="spinnerClass"></div>
      <div class="circle" [ngClass]="spinnerClass"></div>
    </div>
    <div class="set-two">
      <div class="circle" [ngClass]="spinnerClass"></div>
      <div class="circle" [ngClass]="spinnerClass"></div>
    </div>
  </div>
</div>
