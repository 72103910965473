import { ThemeService } from '@core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app//core/models/user';
import { UserService } from '@core/services/user.service';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';
import { TenableFunctionsService } from '@core/tenable/service/tenable-functions.service';
import { Observable, map, isObservable } from 'rxjs';
import { TenableScansService } from '@core/tenable/service/tenable-scans.service';
@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss'],
})
export class PageSidebarComponent implements OnInit {
  sideBarvisible = false;
  public sidebarLinks: {
    title: string;
    route: string | string[];
    iconImg: string;
    count: Observable<string> | string | null;
    future?: boolean;
    sub_links?: {
      title: string;
      route: string | string[];
      iconImg: string;
      count: Observable<string> | string | null;
    }[]
  }[] = [];
  userModel: User | null = null;
  companyModel!: Company;

  activeMenu: string | null = null;

  private _scannersCount$: Observable<string>;  // Private variable for the Observable
  private _scanListCount$: Observable<string>;  // Private variable for the Observable
  private _assetCount$: Observable<string>;  // Private variable for the Observable

  // TODO Add a check to see if keys exist in the KV before making any call
  constructor(
    private themeService: ThemeService,
    private companyService: CompanyService,
    private userService: UserService,
    private tenableService: TenableFunctionsService,
    private scanService: TenableScansService,
  ) {

    this._scannersCount$ = this.getScannersCount(); // Initialize the Observable
    this._scanListCount$ = this.getScanListCount(); // Initialize the Observable
    this._assetCount$ = this.getAssetCount();

    this.themeService.getSidebarIsVisable().subscribe((value: boolean) => {
      this.sideBarvisible = value;
    });

    this.userService.getUserModel().subscribe((user) => {
      if (user) {
        this.userModel = user;
        if (this.userModel.companyId) {
          this.companyService.getDocument(this.userModel.companyId).then((companyProfile: any) => {
            if (companyProfile) {
              this.companyService.company = new Company(companyProfile);
              this.companyModel = new Company(companyProfile);
            }
          })
        }
      }
    });

    this.themeService.getSidebarCompanyModel().subscribe((company: Company) => {
      if (company) {
        this.companyModel = company;
      }
    });
  }

  ngOnInit(): void {

    this.sidebarLinks = [
      {
        title: 'Scanners',
        route: '/dashboard/scanners',
        iconImg: 'fa-file-lines',
        count: this.scannersCount$,  // Using getter
        // sub_links: [
        //   {
        //     title: 'List Scanners',
        //     route: '/dashboard/scanners/list',
        //     iconImg: 'fa-file-lines',
        //     count: null,
        //   },
        //   {
        //     title: 'Create',
        //     route: ['/dashboard/scanners/create'], // /dashboard/scanners/create
        //     iconImg: 'fa-plus',
        //     count: null,
        //   }
        // ]
      },
      {
        title: 'Scans',
        route: '/dashboard/scans',
        iconImg: 'fa-file-lines',
        count: this._scanListCount$,
        // sub_links: [
        //   {
        //     title: 'List Scans',
        //     route: '/dashboard/scans/list',
        //     iconImg: 'fa-file-lines',
        //     count: null,
        //   },
        //   {
        //     title: 'Create Scans',
        //     route: '/dashboard/scan-setup',
        //     iconImg: 'fa-plus',
        //     count: null,
        //   }
        // ]
      },
      // TODO Add Agent Groups
      // {
      //   title: 'Agent Groups',
      //   route: '/dashboard/agent-group',
      //   iconImg: 'fa-object-group',
      //   count: null,
      // },
      {
        title: 'Assets',
        route: '/dashboard/scan-assets',
        iconImg: 'fa-boxes-stacked',
        count: this._assetCount$,
      },
      {
        title: 'Vulnerabiltiy Library',
        route: '/dashboard/cves',
        iconImg: 'fa-shield-virus',
        count: null,
      },


      // Future links
      {
        title: 'Reports',
        route: '/dashboard/reports',
        iconImg: 'fa-file',
        future: true,
        count: null,
      },
      {
        title: 'WAS Statistics',
        route: '/dashboard/was-statistics',
        iconImg: 'fa-file-lines',
        future: true,
        count: null,
      },

      {
        title: 'Asset Statistics',
        route: '/dashboard/asset-statistics',
        iconImg: 'fa-uncharted',
        future: true,
        count: null,
      },

      {
        title: 'Vulnerabiltiy Management',
        route: '/dashboard/vulnerabiltiy-management',
        iconImg: 'fa-network-wired',
        future: true,
        count: null,
      },

      {
        title: 'Automation',
        route: '/dashboard/automation',
        iconImg: 'fa-gears',
        future: true,
        count: null,
      },
      // Assign Employes or uses to each remediation
      {
        title: 'Remediation Projects',
        route: '/dashboard/remediation-project',
        iconImg: 'fa-users',
        future: true,
        count: null,
      },
      {
        title: 'Remediation Owners',
        route: '/dashboard/remediation-owner',
        iconImg: 'fa-users',
        future: true,
        count: null,
      },
      {
        title: 'Messages',
        route: '/dashboard/messages',
        iconImg: 'fa-comment-dots',
        future: true,
        count: '0',
      },
      {
        title: 'Schedule',
        route: '/dashboard/calendar',
        iconImg: 'fa-calendar-days',
        future: true,
        count: null,
      },
      {
        title: 'Support',
        route: '/dashboard/support',
        iconImg: 'fa-question-circle',
        count: null,
      }
    ];
  }


  toggleSidebar() {
    this.themeService.toggleSidebarVisibility();
  }
  // Toggle active status
  toggleActiveMenu(menuTitle: string) {
    this.activeMenu = this.activeMenu === menuTitle ? null : menuTitle;
  }


  isObservable(obj: any): obj is Observable<any> {
    if (obj == null) return false;
    return isObservable(obj);
  }



  // Scanner Counts

  // Getter for scannersCount$
  get scannersCount$(): Observable<string> {
    return this._scannersCount$;
  }

  // Setter for scannersCount$ (if needed for updating the value from other parts of the app)
  set scannersCount$(value: Observable<string>) {
    this._scannersCount$ = value;
  }

  getScannersCount(): Observable<string> {
    return this.tenableService.listScanners().pipe(
      map(scannerList => {
        if (scannerList) {
          this.themeService.setScanListCount(scannerList?.length || 0);
          if(scannerList?.length > 0) {
            this.themeService.setScannerExists(true);
          }
        }
        return scannerList?.length?.toString() || '0'
      })
    );
  }


  // Scan Count
  // Getter for scanListCount$
  get scanListCount$(): Observable<string> {
    return this._scanListCount$;
  }

  // Setter for scanListCount$ (if needed for updating the value from other parts of the app)
  set scanListCount$(value: Observable<string>) {
    this._scanListCount$ = value;
  }

  getScanListCount(): Observable<string> {

    return this.scanService.listScans().pipe(
      map(scanLists => {
        if (scanLists)
          this.themeService.setScanListCount(scanLists.length || 0);

        return scanLists?.length?.toString() || '0'
      })
    );
  }


  // Asset Count
  // Getter for assetCount$
  get assetCount$(): Observable<string> {
    return this._assetCount$;
  }

  // Setter for assetCount$ (if needed for updating the value from other parts of the app)
  set assetCount$(value: Observable<string>) {
    this._assetCount$ = value;
  }

  getAssetCount(): Observable<string> {
    return this.tenableService.getAssetList().pipe(
      map(assetList => {
        if (assetList)
          this.themeService.setAssetCount(Number(assetList.total) || 0);
        return assetList?.total || '0';
      })
    );
  }

}
