
import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  spinnerColor?: string;
  spinnerClass?: string;
  messageClass?: string;


  loadingMessages: string[] = [
      "Training neural networks to make your experience smarter... or at least trying to.",
      "Feeding the algorithm cookies so it works faster. Don't worry, they're digital!",
      "Optimizing the AI's sense of humor. Please laugh at the jokes upon completion.",
      "Teaching the robots not to take over the world. Just a moment.",
      "Currently arguing with the AI about whether it's a cat or a dog person.",
      "Running a Turing test on our server. It's feeling quite human today!",
      "Our AI is currently reading the entire internet. It'll be done in... just a moment.",
      "Calibrating the chatbot's sarcasm levels for optimal sass.",
      "Loading... Please don't unplug the matrix.",
      "Training our AI to understand why humans love cat videos so much.",
      "Uploading today's batch of memes into the neural network.",
      "Fine-tuning the coffee algorithm for peak performance. Beep bop.",
      "Engaging in a deep philosophical debate with our server. About pizza toppings.",
      "Our AI is learning how to roll its virtual eyes. Please stand by.",
      "Trying to convince the AI that you can't solve all problems with more data.",
      "Teaching the AI the difference between 'there', 'their', and 'they're'. It's confused.",
      "Instructing the algorithm on the art of making the perfect toast. Priorities!",
      "Downloading the latest AI jokes. Disclaimer: We can't guarantee they're all funny.",
      "Asking the AI to stop daydreaming about electric sheep. Loading your content!",
      "Our AI is currently binge-watching cat videos for... research purposes."
    ];
  randomMessage: string;

  constructor(private themeService:ThemeService) {
    this.randomMessage = this.getRandomLoadingMessage();
  }

  ngOnInit(): void {
    this.spinnerColor = this.themeService.getSideBarColor();

    switch (this.spinnerColor) {
      case "red":
        this.spinnerClass = "badge-danger";
        this.messageClass = "text-danger";
        break;
      case "primary":
        this.spinnerClass = "badge-primary";
        this.messageClass = "text-primary";
        break;
      case "blue":
        this.spinnerClass = "badge-info";
        this.messageClass = "text-info";
        break;
      case "green":
        this.spinnerClass = "badge-success";
        this.messageClass = "text-success";
      break;
      default:
        this.spinnerClass = "badge-info";
        this.messageClass = "text-info";
        break;
    }
  }

  getRandomLoadingMessage(): string {
    const messages = this.loadingMessages;
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  }

}


export class LoadingMessages {
  loadingMessages!: string[];
}
