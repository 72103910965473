import { Component } from '@angular/core';
import packageJson from '../../../../../package.json';
@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {
  currentDate: Date = new Date();
  public version?: string;

  constructor(){

    if(packageJson) {
   this.version = packageJson.version
    }
  }


}
