export class TenableScanList {
  folders!: any[];
  scans!: TenableScan[];
  timetamp!: number;
}

export class TenableScan {
  companyUid!: string
  control: boolean = false
  creation_date!: number
  enabled: boolean = false
  id!: number
  last_modification_date!: number
  legacy: boolean = false
  name!: string
  owner!: string
  policy_id!: number
  read: boolean = false
  schedule_uuid!: string
  shared: boolean = false
  status!: string
  template_uuid!: string
  has_triggers: boolean = false
  type!: string
  permissions!: number
  user_permissions!: number
  uuid!: string
  wizard_uuid!: string
  progress!: number
  total_targets!: number
  status_times: StatusTimes = new StatusTimes

  constructor(data: object) {
    Object.assign(this, data);
  }
}

export class StatusTimes {
  initializing!: number
  pending!: number
  processing!: number
  publishing!: number
  running!: number
}
